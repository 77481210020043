import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import RichTable from "./RichTable";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    item: {
        height: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        lineHeight: "1rem"
    },
}));

export default function OrganizationGroups({ organizationId, since, until, groupsById, onDelete, admin }) {
    const classes = useStyles();

    const groupTypeToName = {
        "HEATING_COST_ALLOCATION_BUILDING": "Podział ciepła - budynek",
        "HEATING_COST_ALLOCATION_FLAT": "Podział ciepła - mieszkanie",
        "HEATING_COST_ALLOCATION_COMMON_AREA": "Podział ciepła - część wspólna",
        "HEATING_COST_ALLOCATION_ROOM": "Podział ciepła - pomieszczenie",
    };

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} className={classes.item}>
                    <RichTable name="Instalacje" columns={
                        [
                            {
                                id: 'name',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/groups/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>),
                                visible: true
                            },
                            { id: 'id', numeric: false, disablePadding: false, label: 'ID', render: (row) => row.id, visible: true },
                            { id: 'type', numeric: false, disablePadding: false, label: 'Typ', render: (row) => groupTypeToName[row.type], visible: true }
                        ]
                    } initialRowsById={new Map(Array.from(groupsById.values()).filter(value => organizationId === '53d0ea91-5c30-40d5-8036-e97c753743a1' && value.type === "HEATING_COST_ALLOCATION_BUILDING" || organizationId !== '53d0ea91-5c30-40d5-8036-e97c753743a1').map(group => {
                        return [group.id, {
                            name: group.name,
                            id: group.id,
                            type: group.type
                        }];
                    }))} initialOrderBy="name"
                        initialOrder="asc"
                        selectionEnabled
                        filterEnabled
                        sortingEnabled
                        addDeleteEnabled={admin}
                        addUri={"/organizations/" + organizationId + "/groups/create?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}
                        onDelete={onDelete}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}