import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TimedValuePanel from "./TimedValuePanel";
import TimedValueForecastPanel from "./TimedValueForecastPanel";
import TimedValue from "./TimedValue";
import Tooltip from '@material-ui/core/Tooltip';
import { GoogleMap, Marker } from "./GoogleMap";
import { Link } from "react-router-dom";
import RichTable from "./RichTable";
import { Lens } from "@material-ui/icons";
import moment from "moment";
import Title from "./Title";
import TimedValueChart from "./TimedValueChart";
import { randomIntFromInterval, round } from "./common";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  panel: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 144,
  },
  mapPanel: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  map: {
    height: 600,
    width: '100%'
  },
  svgMap: {
    width: "100%",
    height: "auto"
  },
  chartPanel: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 233
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  }
}));

export default function GroupDashboard({
  organizationId,
  groupId,
  group,
  since,
  until,
  groupsById,
  devicesById,
  //groupGroupDeviceAttributesByDeviceId,
  deviceStatusesByDeviceId,
  deviceStatuses,
  kiosk
}) {
  const classes = useStyles();

  function calculateTime(groupDeviceStatuses) {
    const a = groupDeviceStatuses.size > 0 ? Array.from(groupDeviceStatuses.values())
      .map(value => value.length > 0 ? value[0] : null)
      .sort((a, b) => b.time - a.time)[0] : null;
    return a !== null ? a.time : null;
  }

  function calculateTemperature(groupDeviceStatuses) {
    const a = Array.from(groupDeviceStatuses.values())
      .map(value => value.length > 0 ? value[0] : null)
      .sort((a, b) => b.time - a.time)[0];
    return a ? a.temperature : null;
  }

  //const [balanceCold, balanceHot] = React.useMemo(() => [calculateBalance("COLD", since, until, devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId), calculateBalance("HOT", since, until, devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId)], [devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId]);
  //const [totalUsageCold, totalUsageHot] = React.useMemo(() => [calculateTotalUsage("COLD", since, until, devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId), calculateTotalUsage("HOT", since, until, devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId)], [devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId]);
  const time = React.useMemo(() => calculateTime(deviceStatusesByDeviceId), [deviceStatusesByDeviceId]);

  const initialRows = (devicesById.size > 0 && deviceStatusesByDeviceId.size > 0) ? (Array.from(devicesById.values()).map(device => {
    const deviceStatuses = deviceStatusesByDeviceId.get(device.device.id) ? deviceStatusesByDeviceId.get(device.device.id).filter(status => status.isReading) : [];
    const occupied = deviceStatuses.length > 0 && deviceStatuses[0].occupancy && deviceStatuses[0].occupancy.value > 0;
    const labels = device.device.description ? device.device.description.split(",") : [];
    return {
      id: device.device.id,
      name: device.device.name,
      time: calculateTime(deviceStatuses),
      occupied: occupied,
      labels: labels
    }
  })).filter(value => value) : [];

  function ubsFreeSpaces() {
    return (103 - 11) - initialRows.filter(a =>
      a.name !== "B90" &&
      a.name !== "B91" &&
      a.name !== "B92" &&
      a.name !== "B93" &&
      a.name !== "B94" &&
      a.name !== "B95" &&
      a.name !== "B89" &&
      a.name !== "B88" &&
      a.name !== "B35" &&
      a.name !== "B36" &&
      a.name !== "B37" &&
      a.occupied).length;
  }

  let ubsParkingSpacesByDeviceName = new Map(initialRows.map(value => [value.name, value]));

  function ubsLateSpaces() {
    let count = 6;
    if (ubsParkingSpacesByDeviceName.get("B90") && ubsParkingSpacesByDeviceName.get("B90").occupied) {
      count--;
    }
    if (ubsParkingSpacesByDeviceName.get("B91") && ubsParkingSpacesByDeviceName.get("B91").occupied) {
      count--;
    }
    if (ubsParkingSpacesByDeviceName.get("B92") && ubsParkingSpacesByDeviceName.get("B92").occupied) {
      count--;
    }
    if (ubsParkingSpacesByDeviceName.get("B93") && ubsParkingSpacesByDeviceName.get("B93").occupied) {
      count--;
    }
    if (ubsParkingSpacesByDeviceName.get("B94") && ubsParkingSpacesByDeviceName.get("B94").occupied) {
      count--;
    }
    if (ubsParkingSpacesByDeviceName.get("B95") && ubsParkingSpacesByDeviceName.get("B95").occupied) {
      count--;
    }
    return count;
  }

  function ubsReservedSpaces() {
    let count = 3;
    if (ubsParkingSpacesByDeviceName.get("B35") && ubsParkingSpacesByDeviceName.get("B35").occupied) {
      count--;
    }
    if (ubsParkingSpacesByDeviceName.get("B36") && ubsParkingSpacesByDeviceName.get("B36").occupied) {
      count--;
    }
    if (ubsParkingSpacesByDeviceName.get("B37") && ubsParkingSpacesByDeviceName.get("B37").occupied) {
      count--;
    }
    return count;
  }

  function lastFreeSpaceTime() {
    let a = initialRows.sort((a, b) => b.time - a.time)[0];
    return a ? a.time : null;
  }

  function freeSpaces() {
    return initialRows.filter(a => !a.occupied).length;
  }

  function lastFreeRoomTime() {
    let a = initialRows.filter(a => a.labels.includes("room")).sort((a, b) => b.time - a.time)[0];
    return a ? a.time : null;
  }

  function freeRooms() {
    return initialRows.filter(a => a.labels.includes("room")).filter(a => !a.occupied).length;
  }

  function rooms() {
    return initialRows.filter(a => a.labels.includes("room")).length;
  }

  function lastFreeDeskTime() {
    let a = initialRows.filter(a => a.labels.includes("desk")).sort((a, b) => b.time - a.time)[0];
    return a ? a.time : null;
  }

  function freeDesks() {
    return initialRows.filter(a => a.labels.includes("desk")).filter(a => !a.occupied).length;
  }

  function desks() {
    return initialRows.filter(a => a.labels.includes("desk")).length;
  }


  let roomDeskByDeviceId = new Map(initialRows.map(value => [value.id, value]));

  function ubsCalculateFill(place) {
    return ubsParkingSpacesByDeviceName.get(place) && ubsParkingSpacesByDeviceName.get(place).occupied ? "#FF0101" : "#6DD400";
  }

  function calculateFill(place) {
    return roomDeskByDeviceId.get(place) && roomDeskByDeviceId.get(place).occupied ? "#FF0101" : "#6DD400";
  }

  function calculateExternalTemperatures(devicesById, groupId, deviceStatusesByDeviceId) {
    return Array.from(devicesById.values())
      .filter(groupDevice => groupDevice.groupId === groupId)
      .flatMap(groupDevice => {
        const deviceStatuses = deviceStatusesByDeviceId.get(groupDevice.device.id) ? deviceStatusesByDeviceId.get(groupDevice.device.id).filter(status => status !== null && status.isReading === true) : [];
        return deviceStatuses.map(a => a.temperature);
      });
  }

  function calculateExternalTemperature(devicesById, groupId, deviceStatusesByDeviceId) {
    const temp = calculateExternalTemperatures(devicesById, groupId, deviceStatusesByDeviceId);
    return temp.length > 0 ? temp[0] : null;
  }

  function calculateHeatingCosts(devicesById, groupId, deviceStatusesByDeviceId) {
    return Array.from(devicesById.values())
      .filter(groupDevice => groupDevice.groupId === groupId)
      .flatMap(groupDevice => {
        const deviceStatuses = deviceStatusesByDeviceId.get(groupDevice.device.id) ? deviceStatusesByDeviceId.get(groupDevice.device.id).filter(status => status !== null && status.isReading === true) : [];
        return deviceStatuses.map(a => {
          a.value = group.heatingCost * group.deliveredHeat;
          return a;
        });
      });
  }

  function calculateHeatingCost(devicesById, groupId, deviceStatusesByDeviceId) {
    const temp = calculateHeatingCosts(devicesById, groupId, deviceStatusesByDeviceId);
    return temp.length > 0 ? temp[0] : null;
  }

  function calculateHeatingCostPerFlat(buildingGroup, flatGroupId, groupsById, devicesById, deviceStatusesByDeviceId) {
    const cost = groupsById.get(flatGroupId).group.type === "HEATING_COST_ALLOCATION_COMMON_AREA" ? 0 : (group.heatingCost * group.deliveredHeat) / 2;
    return cost;
  }

  function getName(place) {
    return roomDeskByDeviceId.get(place) && roomDeskByDeviceId.get(place).name;
  }

  function generatePathOverlay(id, d) {
    return (
      <Tooltip title={getName(id)} arrow>
        <Link className={classes.link}
          to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>
          <path
            d={d}
            id={id} fill={calculateFill(id)}
            fillOpacity="0.7"></path>
        </Link>
      </Tooltip>);
  }

  function generateRectOverlay(id, x, y, width, height) {
    return (
      <Tooltip title={getName(id)} arrow>
        <Link className={classes.link}
          to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>
          <rect id={id} fill={calculateFill(id)}
            x={x} y={y} width={width} height={height} fillOpacity="0.7"></rect>
        </Link>
      </Tooltip>);
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={2}>
        {groupId === "9a4bf1a5-b58e-4d57-b6c9-e546f5398a39" && <React.Fragment>
          {/*<Typography component="p" variant="h6">Prace konserwacyjne - aplikacja jest obecnie niedostępna. Za utrudnienia przepraszamy.<br />
                                                Maintenance - the application is currently unavailable. We apologize for inconveniences.</Typography>*/}
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.panel}>
                  <TimedValueForecastPanel title="Miejsca Free"
                    unit=""
                    color="black"
                    timedValue={new TimedValue(lastFreeSpaceTime(), Math.round(ubsFreeSpaces()) + " / " + Math.round((103 - 11)))}
                    forecast={Math.round(ubsFreeSpaces()) - randomIntFromInterval(ubsFreeSpaces() - 3, ubsFreeSpaces() + 3, 0, 103 - 11)}
                    onClick={{}} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.panel}>
                  <TimedValueForecastPanel title="Miejsca Late"
                    unit=""
                    color="black"
                    timedValue={new TimedValue(lastFreeSpaceTime(), Math.round(ubsLateSpaces()) + " / " + Math.round(6))}
                    forecast={Math.round(ubsLateSpaces()) - randomIntFromInterval(ubsLateSpaces() - 1, ubsLateSpaces() + 1, 0, 6)}
                    onClick={{}} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.panel}>
                  <TimedValueForecastPanel title="Miejsca Reserved"
                    unit=""
                    color="black"
                    timedValue={new TimedValue(lastFreeSpaceTime(), Math.round(ubsReservedSpaces()) + " / " + Math.round(3))}
                    forecast={Math.round(ubsReservedSpaces()) - randomIntFromInterval(ubsFreeSpaces() - 1, ubsFreeSpaces() + 1, 0, 3)}
                    onClick={{}} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.mapPanel}>
              <Title>Plan</Title>
              <svg viewBox="0 0 2076 1362" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g>
                    <image id="map" x="0" y="0" width="2076" height="1362"
                      xlinkHref="/iss-ubs-dominikanski-plan.png"></image>
                    <circle id="B89" fill={ubsCalculateFill("B89")} cx="110" cy="158.5"
                      r="13.5"></circle>
                    <circle id="B88" fill={ubsCalculateFill("B88")} cx="110" cy="204"
                      r="13.5"></circle>
                    <circle id="B87" fill={ubsCalculateFill("B87")} cx="110" cy="273"
                      r="13.5"></circle>
                    <circle id="B86" fill={ubsCalculateFill("B86")} cx="110" cy="318.5"
                      r="13.5"></circle>
                    <circle id="B85" fill={ubsCalculateFill("B85")} cx="110" cy="389"
                      r="13.5"></circle>
                    <circle id="B84" fill={ubsCalculateFill("B84")} cx="110" cy="439.5"
                      r="13.5"></circle>
                    <circle id="B83" fill={ubsCalculateFill("B83")} cx="110" cy="490"
                      r="13.5"></circle>
                    <circle id="B82" fill={ubsCalculateFill("B82")} cx="110" cy="562"
                      r="13.5"></circle>
                    <circle id="B81" fill={ubsCalculateFill("B81")} cx="110" cy="608"
                      r="13.5"></circle>
                    <circle id="B80" fill={ubsCalculateFill("B80")} cx="110" cy="654"
                      r="13.5"></circle>
                    <circle id="B77" fill={ubsCalculateFill("B77")} cx="329.5" cy="562.5"
                      r="13.5"></circle>
                    <circle id="B78" fill={ubsCalculateFill("B78")} cx="329.5" cy="607.5"
                      r="13.5"></circle>
                    <circle id="B79" fill={ubsCalculateFill("B79")} cx="329.5" cy="652.5"
                      r="13.5"></circle>
                    <circle id="B76" fill={ubsCalculateFill("B76")} cx="438.5" cy="555"
                      r="13.5"></circle>
                    <circle id="B75" fill={ubsCalculateFill("B75")} cx="438.5" cy="600.5"
                      r="13.5"></circle>
                    <circle id="B74" fill={ubsCalculateFill("B74")} cx="438.5" cy="666"
                      r="13.5"></circle>
                    <circle id="B73" fill={ubsCalculateFill("B73")} cx="438.5" cy="711.5"
                      r="13.5"></circle>
                    <circle id="B72" fill={ubsCalculateFill("B72")} cx="438.5" cy="757"
                      r="13.5"></circle>
                    <circle id="B71" fill={ubsCalculateFill("B71")} cx="438.5" cy="826.5"
                      r="13.5"></circle>
                    <circle id="B70" fill={ubsCalculateFill("B70")} cx="438.5" cy="869.5"
                      r="13.5"></circle>
                    <circle id="B69" fill={ubsCalculateFill("B69")} cx="438.5" cy="933.5"
                      r="13.5"></circle>
                    <circle id="B68" fill={ubsCalculateFill("B68")} cx="438.5" cy="979"
                      r="13.5"></circle>
                    <circle id="B67" fill={ubsCalculateFill("B67")} cx="438.5" cy="1039"
                      r="13.5"></circle>
                    <circle id="B66" fill={ubsCalculateFill("B66")} cx="438.5" cy="1084.5"
                      r="13.5"></circle>
                    <circle id="B65" fill={ubsCalculateFill("B65")} cx="438.5" cy="1144.5"
                      r="13.5"></circle>
                    <circle id="B64" fill={ubsCalculateFill("B64")} cx="438.5" cy="1190"
                      r="13.5"></circle>
                    <circle id="B63" fill={ubsCalculateFill("B63")} cx="517" cy="1273"
                      r="13.5"></circle>
                    <circle id="B62" fill={ubsCalculateFill("B62")} cx="563" cy="1273"
                      r="13.5"></circle>
                    <circle id="B61" fill={ubsCalculateFill("B61")} cx="609" cy="1273"
                      r="13.5"></circle>
                    <circle id="B60" fill={ubsCalculateFill("B60")} cx="671.5" cy="1273"
                      r="13.5"></circle>
                    <circle id="B59" fill={ubsCalculateFill("B59")} cx="722.5" cy="1273"
                      r="13.5"></circle>
                    <circle id="B58" fill={ubsCalculateFill("B58")} cx="773.5" cy="1273"
                      r="13.5"></circle>
                    <circle id="B57" fill={ubsCalculateFill("B57")} cx="836" cy="1273"
                      r="13.5"></circle>
                    <circle id="B56" fill={ubsCalculateFill("B56")} cx="884.5" cy="1273"
                      r="13.5"></circle>
                    <circle id="B55" fill={ubsCalculateFill("B55")} cx="935.5" cy="1273"
                      r="13.5"></circle>
                    <circle id="B55A" fill={ubsCalculateFill("B55A")} cx="1000.5" cy="1273"
                      r="13.5"></circle>
                    <circle id="B50" fill={ubsCalculateFill("B50")} cx="789.5" cy="1068.5"
                      r="13.5"></circle>
                    <circle id="B51" fill={ubsCalculateFill("B51")} cx="840.5" cy="1068.5"
                      r="13.5"></circle>
                    <circle id="B52" fill={ubsCalculateFill("B52")} cx="888.5" cy="1068.5"
                      r="13.5"></circle>
                    <circle id="B53" fill={ubsCalculateFill("B53")} cx="952.5" cy="1068.5"
                      r="13.5"></circle>
                    <circle id="B54" fill={ubsCalculateFill("B54")} cx="1000.5" cy="1068.5"
                      r="13.5"></circle>
                    <circle id="B49" fill={ubsCalculateFill("B49")} cx="657.5" cy="978"
                      r="13.5"></circle>
                    <circle id="B48" fill={ubsCalculateFill("B48")} cx="657.5" cy="932.5"
                      r="13.5"></circle>
                    <circle id="B47" fill={ubsCalculateFill("B47")} cx="673.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B113" fill={ubsCalculateFill("B113")} cx="673.5" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B112" fill={ubsCalculateFill("B112")} cx="721.5" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B111" fill={ubsCalculateFill("B111")} cx="772.5" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B110" fill={ubsCalculateFill("B110")} cx="836" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B109" fill={ubsCalculateFill("B109")} cx="885" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B108" fill={ubsCalculateFill("B108")} cx="936" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B107" fill={ubsCalculateFill("B107")} cx="999.5" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B106" fill={ubsCalculateFill("B106")} cx="1048.5" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B105" fill={ubsCalculateFill("B105")} cx="1099.5" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B104" fill={ubsCalculateFill("B104")} cx="1163" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B103" fill={ubsCalculateFill("B103")} cx="1212" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B102" fill={ubsCalculateFill("B102")} cx="1263" cy="535.5"
                      r="13.5"></circle>
                    <circle id="B101" fill={ubsCalculateFill("B101")} cx="1339" cy="460"
                      r="13.5"></circle>
                    <circle id="B100" fill={ubsCalculateFill("B100")} cx="1339" cy="409.5"
                      r="13.5"></circle>
                    <circle id="B92" fill={ubsCalculateFill("B92")} cx="1334.5" cy="181"
                      r="13.5"></circle>
                    <circle id="B91" fill={ubsCalculateFill("B91")} cx="1334.5" cy="135"
                      r="13.5"></circle>
                    <circle id="B90" fill={ubsCalculateFill("B90")} cx="1334.5" cy="89"
                      r="13.5"></circle>
                    <circle id="B94" fill={ubsCalculateFill("B94")} cx="930.5" cy="194.5"
                      r="13.5"></circle>
                    <circle id="B95" fill={ubsCalculateFill("B95")} cx="810.5" cy="194.5"
                      r="13.5"></circle>
                    <circle id="B97" fill={ubsCalculateFill("B97")} cx="674.5" cy="228.5"
                      r="13.5"></circle>
                    <circle id="B96" fill={ubsCalculateFill("B96")} cx="721.5" cy="228.5"
                      r="13.5"></circle>
                    <circle id="B98" fill={ubsCalculateFill("B98")} cx="684.5" cy="331.5"
                      r="13.5"></circle>
                    <circle id="B99" fill={ubsCalculateFill("B99")} cx="733.5" cy="331.5"
                      r="13.5"></circle>
                    <circle id="B93" fill={ubsCalculateFill("B93")} cx="1081.5" cy="189.5"
                      r="13.5"></circle>
                    <circle id="B46" fill={ubsCalculateFill("B46")} cx="724.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B45" fill={ubsCalculateFill("B45")} cx="771.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B44" fill={ubsCalculateFill("B44")} cx="835.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B43" fill={ubsCalculateFill("B43")} cx="886.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B42" fill={ubsCalculateFill("B42")} cx="937.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B41" fill={ubsCalculateFill("B41")} cx="999.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B40" fill={ubsCalculateFill("B40")} cx="1048.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B39" fill={ubsCalculateFill("B39")} cx="1099.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B38" fill={ubsCalculateFill("B38")} cx="1169.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B37" fill={ubsCalculateFill("B37")} cx="1218.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B36" fill={ubsCalculateFill("B36")} cx="1343.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B35" fill={ubsCalculateFill("B35")} cx="1420.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B34" fill={ubsCalculateFill("B34")} cx="1469.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B33" fill={ubsCalculateFill("B33")} cx="1521.5" cy="840"
                      r="13.5"></circle>
                    <circle id="B32" fill={ubsCalculateFill("B32")} cx="1585.5" cy="853.5"
                      r="13.5"></circle>
                    <circle id="B31" fill={ubsCalculateFill("B31")} cx="1561.5" cy="932.5"
                      r="13.5"></circle>
                    <circle id="B30" fill={ubsCalculateFill("B30")} cx="1561.5" cy="977.5"
                      r="13.5"></circle>
                    <circle id="B29" fill={ubsCalculateFill("B29")} cx="1599.5" cy="1272.5"
                      r="13.5"></circle>
                    <circle id="B28" fill={ubsCalculateFill("B28")} cx="1649.5" cy="1272.5"
                      r="13.5"></circle>
                    <circle id="B27" fill={ubsCalculateFill("B27")} cx="1699.5" cy="1272.5"
                      r="13.5"></circle>
                    <circle id="B26" fill={ubsCalculateFill("B26")} cx="1762.5" cy="1272.5"
                      r="13.5"></circle>
                    <circle id="B25" fill={ubsCalculateFill("B25")} cx="1812.5" cy="1272.5"
                      r="13.5"></circle>
                    <circle id="B24" fill={ubsCalculateFill("B24")} cx="1862.5" cy="1272.5"
                      r="13.5"></circle>
                    <circle id="B23" fill={ubsCalculateFill("B23")} cx="1925.5" cy="1272.5"
                      r="13.5"></circle>
                    <circle id="B22" fill={ubsCalculateFill("B22")} cx="1975.5" cy="1272.5"
                      r="13.5"></circle>
                    <circle id="B16" fill={ubsCalculateFill("B16")} cx="1762.5" cy="1056.5"
                      r="13.5"></circle>
                    <circle id="B15" fill={ubsCalculateFill("B15")} cx="1776" cy="977.5"
                      r="13.5"></circle>
                    <circle id="B14" fill={ubsCalculateFill("B14")} cx="1776" cy="932.5"
                      r="13.5"></circle>
                    <circle id="B13" fill={ubsCalculateFill("B13")} cx="1776" cy="870.5"
                      r="13.5"></circle>
                    <circle id="B12" fill={ubsCalculateFill("B12")} cx="1776" cy="826.5"
                      r="13.5"></circle>
                    <circle id="B17" fill={ubsCalculateFill("B17")} cx="1813.5" cy="1056.5"
                      r="13.5"></circle>
                    <circle id="B18" fill={ubsCalculateFill("B18")} cx="1863" cy="1056.5"
                      r="13.5"></circle>
                    <circle id="B19" fill={ubsCalculateFill("B19")} cx="1926.5" cy="1056.5"
                      r="13.5"></circle>
                    <circle id="B20" fill={ubsCalculateFill("B20")} cx="1974" cy="1056.5"
                      r="13.5"></circle>
                    <circle id="B21" fill={ubsCalculateFill("B21")} cx="2021.5" cy="1056.5"
                      r="13.5"></circle>
                  </g>
                </g>
              </svg>
            </Paper>
          </Grid>
        </React.Fragment>}
        {groupId === "1d65de3f-e674-4854-9f70-de16075ca02b" && <React.Fragment>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Paper className={classes.panel}>
                  <TimedValuePanel title="Wolne salki"
                    unit=""
                    color="black"
                    timedValue={new TimedValue(lastFreeRoomTime(), Math.round(freeRooms()) + " / " + Math.round(rooms()))}
                    onClick={{}} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Paper className={classes.panel}>
                  <TimedValuePanel title="Wolne biurka"
                    unit=""
                    color="black"
                    timedValue={new TimedValue(lastFreeDeskTime(), Math.round(freeDesks()) + " / " + Math.round(desks()))}
                    onClick={{}} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.mapPanel}>
              <Title>Plan</Title>
              <svg viewBox="0 0 5835 1665" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink">
                <image x="0" y="0" xlinkHref="/iss-pmi-hub-plan.png"></image>
                <path
                  d="M164.893333,530.921053 C169.210936,530.921053 173.026491,533.747281 174.432222,537.96093 L174.432222,537.96093 L187.184211,577.168421 L172.122807,577.168421 L172.122807,608 L157.061404,608 L157.061404,577.168421 L142,577.168421 L154.751988,537.96093 C156.107515,533.747281 159.973275,530.921053 164.290877,530.921053 L164.290877,530.921053 Z M164.179673,507 C170.0802,507 174.811252,511.731053 174.811252,517.631579 C174.811252,523.532105 170.0802,528.263158 164.179673,528.263158 C158.279147,528.263158 153.548094,523.532105 153.548094,517.631579 C153.548094,511.731053 158.279147,507 164.179673,507 Z"
                  fill="#CACACA"></path>
                <path
                  d="M173.680451,1046.92105 C179.11015,1046.92105 183.552632,1051.54579 183.552632,1057.19825 L183.552632,1085.46053 L176.148496,1085.46053 L176.148496,1124 L156.404135,1124 L156.404135,1085.46053 L149,1085.46053 L149,1057.19825 C149,1051.54579 153.442481,1046.92105 158.87218,1046.92105 L173.680451,1046.92105 Z M167.605263,1023 C173.505789,1023 178.236842,1027.73105 178.236842,1033.63158 C178.236842,1039.53211 173.505789,1044.26316 167.605263,1044.26316 C161.704737,1044.26316 156.973684,1039.53211 156.973684,1033.63158 C156.973684,1027.73105 161.704737,1023 167.605263,1023 Z"
                  fill="#CACACA"></path>
                <path
                  d="M3635.89333,1479.92105 C3640.21094,1479.92105 3644.02649,1482.74728 3645.43222,1486.96093 L3645.43222,1486.96093 L3658.18421,1526.16842 L3643.12281,1526.16842 L3643.12281,1557 L3628.0614,1557 L3628.0614,1526.16842 L3613,1526.16842 L3625.75199,1486.96093 C3627.10751,1482.74728 3630.97327,1479.92105 3635.29088,1479.92105 L3635.29088,1479.92105 Z M3635.17967,1456 C3641.0802,1456 3645.81125,1460.73105 3645.81125,1466.63158 C3645.81125,1472.53211 3641.0802,1477.26316 3635.17967,1477.26316 C3629.27915,1477.26316 3624.54809,1472.53211 3624.54809,1466.63158 C3624.54809,1460.73105 3629.27915,1456 3635.17967,1456 Z"
                  fill="#CACACA"></path>
                <path
                  d="M3949.68045,1479.92105 C3955.11015,1479.92105 3959.55263,1484.54579 3959.55263,1490.19825 L3959.55263,1518.46053 L3952.1485,1518.46053 L3952.1485,1557 L3932.40414,1557 L3932.40414,1518.46053 L3925,1518.46053 L3925,1490.19825 C3925,1484.54579 3929.44248,1479.92105 3934.87218,1479.92105 L3949.68045,1479.92105 Z M3942.60526,1456 C3948.50579,1456 3953.23684,1460.73105 3953.23684,1466.63158 C3953.23684,1472.53211 3948.50579,1477.26316 3942.60526,1477.26316 C3936.70474,1477.26316 3931.97368,1472.53211 3931.97368,1466.63158 C3931.97368,1460.73105 3936.70474,1456 3942.60526,1456 Z"
                  fill="#CACACA"></path>
                {generatePathOverlay("eui64-647fda0000004978", "M2615,1362 L2615,1273 L2679,1273 L2679,1191 L2922,1191 L2922,1362 L2615,1362 Z")}
                {generatePathOverlay("eui64-647fda0000004975", "M2441,1270 L2441,1166 L2605,1166 L2605,1166 L2605,1270 L2441,1270 Z")}
                {generateRectOverlay("eui64-647fda000000498f", 4149, 1187, 165, 103)}
                {generateRectOverlay("eui64-647fda00000049ea", 1541, 1166, 164, 103)}
                {generatePathOverlay("eui64-647fda0000004961", "M1228,1370 L1530,1370 L1530,1515 L1468,1515 L1468,1565 L1228,1565 L1228,1370 Z")}
                {generatePathOverlay("eui64-647fda00000049e8", "M1228,1361 L1228,1191 L1468,1191 L1468,1272 L1530,1272 L1530,1361 L1228,1361 Z")}
                {generatePathOverlay("eui64-647fda0000004a7e", "M98,1360 L98,1191 L330,1191 L330,1272 L393,1272 L393,1360 L98,1360 Z")}
                {generatePathOverlay("eui64-647fda000000496a", "M94,255 L94,63 L331,63 L331,109 L396,109 L396,255 L94,255 Z")}
                {generateRectOverlay("eui64-647fda0000004977", 1305, 333, 165, 97)}
                {generateRectOverlay("eui64-647fda000000498e", 2680, 62, 268, 167)}
                {generateRectOverlay("eui64-647fda0000004a77", 2680, 238, 268, 205)}
                {generateRectOverlay("eui64-647fda0000004a87", 4136, 681, 104, 149)}
                {generatePathOverlay("eui64-647fda000000498b", "M5180,243 L5180,443 L4953,443 L4953,361 L4890,361 L4890,243 L5180,243 Z")}
                {generatePathOverlay("eui64-647fda0000004962", "M4890,233 L4890,111 L4953,111 L4953,60 L5180,60 L5180,233 L4890,233 Z")}
                {generateRectOverlay("eui64-647fda000000497d", 4136, 833, 104, 149)}
                {generatePathOverlay("eui64-647fda0000004a85", "M332,415 L396,415 L396,474 L94,474 L94,264 L397,264 L397,361 L332,361 L332,415 Z")}
                {generatePathOverlay("eui64-647fda000000497b", "M330,1561 L98,1561 L98,1370 L393,1370 L393,1515 L330,1515 L330,1561 Z")}
                {generatePathOverlay("eui64-647fda000000496b", "M2922,1369 L2922,1566 L2679,1566 L2679,1514 L2615,1514 L2615,1369 L2922,1369 Z")}
                {generatePathOverlay("eui64-647fda0000004988", "M5734,1565 L5232,1565 L5232,1192 L5734,1192 L5734,1272 L5765,1272 L5765,1525 L5734,1525 L5734,1565 Z")}
                {generatePathOverlay("eui64-647fda000000495f", "M3358,1191 L3358,1272 L3388,1272 L3388,1517 L3358,1517 L3358,1566 L2934,1566 L2934,1191 L3358,1191 Z")}
              </svg>
            </Paper>
          </Grid>
        </React.Fragment>}
        {groupId === "569f6b3f-303b-4c1b-acc4-3cf4fb97da99" && <React.Fragment><Grid item xs={12} md={4} lg={4}>
          <Paper className={classes.panel}>
            <TimedValuePanel title="Wolne miejsca parkingowe"
              unit=""
              color="black"
              timedValue={new TimedValue(lastFreeSpaceTime(), Math.round(freeSpaces()))}
              onClick={{}}
              precision="0" />
          </Paper>
        </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.mapPanel}>
              <Title>Mapa parkingu</Title>
              <svg className={classes.svgMap} viewBox="0 0 631 178" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Group-2">
                    <g id="Group" transform="translate(53.000000, 43.000000)">
                      <text id="159" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="12" y="83">159</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="43"
                        height="90"></rect>
                    </g>
                    <g id="Group" transform="translate(100.000000, 43.000000)">
                      <text id="160" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="12" y="83">160</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="43"
                        height="90"></rect>
                    </g>
                    <rect id="Rectangle" stroke="#828282" x="146.5" y="107.5" width="6"
                      height="18"></rect>
                    <rect id="Rectangle" stroke="#828282" x="248.5" y="107.5" width="7"
                      height="18"></rect>
                    <rect id="Rectangle" stroke="#828282" x="394.5" y="107.5" width="7"
                      height="18"></rect>
                    <g id="Group" transform="translate(155.000000, 43.000000)">
                      <text id="161" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="12" y="83">161</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="43"
                        height="90"></rect>
                    </g>
                    <g id="Group" transform="translate(203.000000, 43.000000)">
                      <text id="162" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="11" y="83">162</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="42"
                        height="90"></rect>
                    </g>
                    <g id="Group" transform="translate(258.000000, 43.000000)">
                      <text id="163" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="11" y="83">163</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="42"
                        height="90"></rect>
                    </g>
                    <g id="Group" transform="translate(303.000000, 43.000000)">
                      <text id="164" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="12" y="83">164</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="43"
                        height="90"></rect>
                    </g>
                    <g id="Group" transform="translate(349.000000, 43.000000)">
                      <text id="165" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="11" y="83">165</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="42"
                        height="90"></rect>
                    </g>
                    <g id="Group" transform="translate(404.000000, 43.000000)">
                      <text id="166" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="11" y="83">166</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="42"
                        height="90"></rect>
                    </g>
                    <g id="Group" transform="translate(479.000000, 43.000000)">
                      <text id="44" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="14" y="83">44</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="43"
                        height="90"></rect>
                    </g>
                    <g id="Group" transform="translate(525.000000, 43.000000)">
                      <text id="47" fontFamily="Roboto-Regular, Roboto" fontSize="12"
                        fontWeight="normal" fill="#828282">
                        <tspan x="14" y="83">47</tspan>
                      </text>
                      <rect id="Rectangle" stroke="#828282" x="0.5" y="0.5" width="42"
                        height="90"></rect>
                    </g>
                    {/*<g id="ev_station_black_24dp" transform="translate(452.000000, 77.000000)">
                                        <polygon id="Path" points="0 0 34 0 34 34 0 34"></polygon>
                                        <path
                                            d="M27.9824242,10.11 L27.9963636,10.0955556 L22.8109091,4.72222222 L21.3333333,6.25333333 L24.2745455,9.30111111 C22.9642424,9.82111111 22.030303,11.1211111 22.030303,12.6666667 C22.030303,14.66 23.5915152,16.2777778 25.5151515,16.2777778 C26.0169697,16.2777778 26.4769697,16.1622222 26.9090909,15.9744444 L26.9090909,26.3888889 C26.9090909,27.1833333 26.2818182,27.8333333 25.5151515,27.8333333 C24.7484848,27.8333333 24.1212121,27.1833333 24.1212121,26.3888889 L24.1212121,19.8888889 C24.1212121,18.3 22.8666667,17 21.3333333,17 L19.9393939,17 L19.9393939,6.88888889 C19.9393939,5.3 18.6848485,4 17.1515152,4 L8.78787879,4 C7.25454545,4 6,5.3 6,6.88888889 L6,30 L19.9393939,30 L19.9393939,19.1666667 L22.030303,19.1666667 L22.030303,26.3888889 C22.030303,28.3822222 23.5915152,30 25.5151515,30 C27.4387879,30 29,28.3822222 29,26.3888889 L29,12.6666667 C29,11.67 28.609697,10.76 27.9824242,10.11 Z M25.5151515,14.1111111 C24.7484848,14.1111111 24.1212121,13.4611111 24.1212121,12.6666667 C24.1212121,11.8722222 24.7484848,11.2222222 25.5151515,11.2222222 C26.2818182,11.2222222 26.9090909,11.8722222 26.9090909,12.6666667 C26.9090909,13.4611111 26.2818182,14.1111111 25.5151515,14.1111111 Z M11.5757576,25.6666667 L11.5757576,19.1666667 L8.78787879,19.1666667 L14.3636364,8.33333333 L14.3636364,15.5555556 L17.1515152,15.5555556 L11.5757576,25.6666667 Z"
                                            id="Shape" fill="#828282" fillRule="nonzero"></path>
                                    </g>
                                    <g id="ev_station_black_24dp" transform="translate(500.000000, 77.000000)">
                                        <polygon id="Path" points="0 0 34 0 34 34 0 34"></polygon>
                                        <path
                                            d="M27.9824242,10.11 L27.9963636,10.0955556 L22.8109091,4.72222222 L21.3333333,6.25333333 L24.2745455,9.30111111 C22.9642424,9.82111111 22.030303,11.1211111 22.030303,12.6666667 C22.030303,14.66 23.5915152,16.2777778 25.5151515,16.2777778 C26.0169697,16.2777778 26.4769697,16.1622222 26.9090909,15.9744444 L26.9090909,26.3888889 C26.9090909,27.1833333 26.2818182,27.8333333 25.5151515,27.8333333 C24.7484848,27.8333333 24.1212121,27.1833333 24.1212121,26.3888889 L24.1212121,19.8888889 C24.1212121,18.3 22.8666667,17 21.3333333,17 L19.9393939,17 L19.9393939,6.88888889 C19.9393939,5.3 18.6848485,4 17.1515152,4 L8.78787879,4 C7.25454545,4 6,5.3 6,6.88888889 L6,30 L19.9393939,30 L19.9393939,19.1666667 L22.030303,19.1666667 L22.030303,26.3888889 C22.030303,28.3822222 23.5915152,30 25.5151515,30 C27.4387879,30 29,28.3822222 29,26.3888889 L29,12.6666667 C29,11.67 28.609697,10.76 27.9824242,10.11 Z M25.5151515,14.1111111 C24.7484848,14.1111111 24.1212121,13.4611111 24.1212121,12.6666667 C24.1212121,11.8722222 24.7484848,11.2222222 25.5151515,11.2222222 C26.2818182,11.2222222 26.9090909,11.8722222 26.9090909,12.6666667 C26.9090909,13.4611111 26.2818182,14.1111111 25.5151515,14.1111111 Z M11.5757576,25.6666667 L11.5757576,19.1666667 L8.78787879,19.1666667 L14.3636364,8.33333333 L14.3636364,15.5555556 L17.1515152,15.5555556 L11.5757576,25.6666667 Z"
                                            id="Shape" fill="#828282" fillRule="nonzero"></path>
                                    </g>*/}
                    <circle id="44" fill={calculateFill("44")} cx="501" cy="58" r="8"></circle>
                    <circle id="166" fill={calculateFill("166")} cx="427" cy="58" r="8"></circle>
                    <circle id="165" fill={calculateFill("165")} cx="371" cy="58" r="8"></circle>
                    <circle id="164" fill={calculateFill("164")} cx="325" cy="58" r="8"></circle>
                    <circle id="163" fill={calculateFill("163")} cx="280" cy="58" r="8"></circle>
                    <circle id="162" fill={calculateFill("162")} cx="225" cy="58" r="8"></circle>
                    <circle id="161" fill={calculateFill("161")} cx="177" cy="58" r="8"></circle>
                    <circle id="160" fill={calculateFill("160")} cx="122" cy="58" r="8"></circle>
                    <circle id="159" fill={calculateFill("159")} cx="76" cy="58" r="8"></circle>
                    <rect id="Rectangle" x="0" y="0" width="601" height="178"></rect>
                    <circle id="47" fill={calculateFill("47")} cx="547" cy="58" r="8"></circle>
                  </g>
                </g>
              </svg>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <RichTable name="Miejsca parkingowe" columns={
              [
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: 'Nazwa',
                  render: (row) => kiosk ? row.name : (<Link className={classes.link}
                    to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>),
                  visible: true
                },
                {
                  id: 'occupied',
                  numeric: true,
                  disablePadding: true,
                  label: 'Zajętość',
                  render: (row) => (<Lens style={{
                    color: row.occupied === true ? "#FF0101" : "#6DD400",
                    verticalAlign: "middle",
                    fontSize: "1rem"
                  }} />),
                  visible: true
                },
                {
                  id: 'time',
                  numeric: true,
                  disablePadding: true,
                  label: 'Czas',
                  render: (row) => row.time,
                  visible: true
                }
              ]
            } initialRowsById={new Map(initialRows.map(device => {
              return [device.id, {
                id: device.id,
                name: device.name,
                time: device.time ? moment(device.time).format("YYYY-MM-DD HH:mm:ss") : null,
                occupied: device.occupied,
              }];
            }))} initialOrderBy="name"
              initialOrder="asc"
            />
          </Grid></React.Fragment>}
        {groupId === "1e897df2-e0ab-40f4-b550-ed47392b744d" &&
          <React.Fragment><Grid item xs={12} md={12} lg={12}>
            <Paper className={classes.chartPanel}>
              <TimedValueChart title="Temperatura"
                label=" °C"
                color="black"
                entries={[]}
                precision="3" />
            </Paper>
          </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.chartPanel}>
                <TimedValueChart title="Wilgotność"
                  label=" °C"
                  color="black"
                  entries={[]}
                  precision="3" />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.mapPanel}>
                <Title>Pozycja</Title>
                <GoogleMap
                  id="myMap"
                  apiKey="AIzaSyDnhoSzehCm0Lb9iFF38n6_mG2-JX5tY7A"
                  options={{
                    center: { lat: 53.2612118, lng: 16.4339246 },
                    zoom: 14,
                    fullscreenControl: false,
                    streetViewControl: false,

                  }}
                  onMapLoad={map => {

                  }}
                  className={classes.map}
                >
                  <Marker id="" name="1" lat="53.2612118" long="16.4339246" />
                </GoogleMap>
              </Paper>
            </Grid>
          </React.Fragment>
        }
        {group !== null && group.type === "HEATING_COST_ALLOCATION_BUILDING" &&
          <React.Fragment>
            <Grid item xs={6} md={6} lg={6}>
              <Paper className={classes.panel}>
                <TimedValuePanel title="Temperatura zewnętrzna"
                  unit=" °C"
                  color="black"
                  timedValue={calculateExternalTemperature(devicesById, groupId, deviceStatusesByDeviceId)}
                  precision="3" />
              </Paper>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Paper className={classes.panel}>
                <TimedValuePanel title="Koszt ogrzewania"
                  unit=" PLN"
                  color="black"
                  timedValue={calculateHeatingCost(devicesById, groupId, deviceStatusesByDeviceId)}
                  precision="2" />
              </Paper>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Paper className={classes.chartPanel}>
                <TimedValueChart title="Temperatura zewnętrzna"
                  label=" °C"
                  color="black"
                  entries={calculateExternalTemperatures(devicesById, groupId, deviceStatusesByDeviceId)}
                  precision="2" />
              </Paper>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Paper className={classes.chartPanel}>
                <TimedValueChart title="Koszt ogrzewania"
                  label=" PLN"
                  color="black"
                  entries={calculateHeatingCosts(devicesById, groupId, deviceStatusesByDeviceId)}
                  precision="3" />
              </Paper>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.chartPanel}>
                <TimedValueChart title="Koszt ogrzewania"
                  label=" PLN"
                  color="black"
                  entries={calculateHeatingCost()} />
              </Paper>
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
              <RichTable name="Lokale" columns={
                [
                  {
                    id: 'id',
                    numeric: false,
                    disablePadding: false,
                    label: 'ID',
                    render: (row) => (<Link className={classes.link}
                      to={"/organizations/" + organizationId + "/groups/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>),
                    hidden: true
                  },
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: 'Nazwa',
                    render: (row) => (<Link className={classes.link}
                      to={"/organizations/" + organizationId + "/groups/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>),
                    visible: true
                  },
                  { id: 'floorArea', numeric: true, disablePadding: false, label: 'Pole powierzchni podłogi (m²)', visible: true },
                  { id: 'averageTemperature', numeric: true, disablePadding: false, label: 'Średnia temperatura (°C)', visible: true },
                  { id: 'heatingCost', numeric: true, disablePadding: false, label: 'Koszt ogrzewania (PLN)', visible: true }
                ]
              } initialRowsById={new Map(Array.from(groupsById.values()).filter(value => value.parentGroupId === groupId).map(group => {
                return [group.group.id, {
                  id: group.group.id,
                  name: group.group.name,
                  floorArea: group.group.floorArea,
                  averageTemperature: 0,
                  heatingCost: calculateHeatingCostPerFlat(group, group.group.id, groupsById, devicesById, deviceStatusesByDeviceId)
                }];
              }))} initialOrderBy="name"
                initialOrder="asc"
                selectionEnabled
                filterEnabled
                sortingEnabled
              />
            </Grid>
          </React.Fragment>
        }
      </Grid>
    </Container>
  );
}