import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { saveAs } from "file-saver"
import { convertArrayToCSV } from "convert-array-to-csv";
import { calculateState, calculateUsage } from "./common";
import moment from "moment";
import { Link } from "react-router-dom";
import RichTable from "./RichTable";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  panel: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  }
}));

export default function GroupDevices({
  organizationId,
  groupId,
  since,
  until,
  devices,
  deviceStatuses,
  onDelete,
  admin
}) {
  const classes = useStyles();

  function deviceHasAlarms(deviceStatus) {
    if (deviceStatus) {
      return ((deviceStatus.meter1Connected ? (deviceStatus.meter1Connected.value === true ? deviceStatus.meter1Connected.value : false) : false)
        || (deviceStatus.meter2Connected ? (deviceStatus.meter2Connected.value === true ? deviceStatus.meter2Connected.value : false) : false)
        || (deviceStatus.meter1Disconnected ? (deviceStatus.meter1Disconnected.value === true ? deviceStatus.meter1Disconnected.value : false) : false)
        || (deviceStatus.meter2Disconnected ? (deviceStatus.meter2Disconnected.value === true ? deviceStatus.meter2Disconnected.value : false) : false)
        || (deviceStatus.meter1DoseExceeded ? (deviceStatus.meter1DoseExceeded.value === true ? deviceStatus.meter1DoseExceeded.value : false) : false)
        || (deviceStatus.meter2DoseExceeded ? (deviceStatus.meter2DoseExceeded.value === true ? deviceStatus.meter2DoseExceeded.value : false) : false)
        || (deviceStatus.meter1HoseCracked ? (deviceStatus.meter1HoseCracked.value === true ? deviceStatus.meter1HoseCracked.value : false) : false)
        || (deviceStatus.meter2HoseCracked ? (deviceStatus.meter2HoseCracked.value === true ? deviceStatus.meter2HoseCracked.value : false) : false)
        || (deviceStatus.meter1Leak ? (deviceStatus.meter1Leak.value === true ? deviceStatus.meter1Leak.value : false) : false)
        || (deviceStatus.meter2Leak ? (deviceStatus.meter2Leak.value === true ? deviceStatus.meter2Leak.value : false) : false)
        || (deviceStatus.valve1Leak ? (deviceStatus.valve1Leak.value === true ? deviceStatus.valve1Leak.value : false) : false)
        || (deviceStatus.valve2Leak ? (deviceStatus.valve2Leak.value === true ? deviceStatus.valve2Leak.value : false) : false)
        || (deviceStatus.valve1Opened ? (deviceStatus.valve1Opened.value === true ? deviceStatus.valve1Opened.value : false) : false)
        || (deviceStatus.valve2Opened ? (deviceStatus.valve2Opened.value === true ? deviceStatus.valve2Opened.value : false) : false)
        || (deviceStatus.valve1Closed ? (deviceStatus.valve1Closed.value === true ? deviceStatus.valve1Closed.value : false) : false)
        || (deviceStatus.valve2Closed ? (deviceStatus.valve2Closed.value === true ? deviceStatus.valve2Closed.value : false) : false)
        || (deviceStatus.batteryLow ? (deviceStatus.batteryLow.value ? deviceStatus.batteryLow.value : false) : false));
    } else {
      return false;
    }
  }

  function chooseMeterId(device, waterType) {
    if (device.meter1WaterType === waterType) {
      return device.meter1Id;
    } else if (device.meter2WaterType === waterType) {
      return device.meter2Id;
    }
    return null;
  }

  function chooseAlarm(device, statuses, alarmType1, alarmType2, waterType) {
    if (device.meter1WaterType === waterType) {
      const find = statuses.find(status => status.isAlarm && status[alarmType1]);
      return find ? (find[alarmType1].value === true ? "tak" : null) : null;
    }
    if (device.meter2WaterType === waterType) {
      const find = statuses.find(status => status.isAlarm && status[alarmType2]);
      return find ? (find[alarmType2].value === true ? "tak" : null) : null;
    }
    return null;
  }

  function onDownload(selection, downloadType) {
    const date = until ? until : new Date;
    const dateStr = moment(date).format("YYYY-MM-DD-HH-mm-ss");
    if (downloadType === "FILA") {
      const columns = [
        "Nazwa",
        "ID",
        "Słaba bateria",
        "Napięcie baterii (V)"
      ];

      const rows = Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.device.id)).map(device => {
        const statuses = deviceStatuses.get(device.device.id);
        return [
          device.device.name,
          device.device.name,
          statuses && statuses.length > 0 ? (statuses[0].batteryLow ? "tak" : null) : null,
          statuses && statuses.length > 0 ? (statuses[0].batteryVoltage ? statuses[0].batteryVoltage : null) : null
        ];
      });

      const csv = convertArrayToCSV(rows, {
        header: columns,
        separator: ','
      });
      const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "metering-fila-" + dateStr + ".csv");
    }
  }

  const deviceTypeToName = {
    "FILA_HEATING_COST_ALLOCATOR": "Podzielnik ciepła Fila",
    "DRAGINO_LHT65": "Dragino LHT65",
    "DRAGINO_LGT92": "Dragino LGT92",
    "DRAGINO_LDS01": "Dragino LDS01",
    "DRAGINO_LWL01": "Dragino LWL01",
    "RAK_7204": "RAK 7204",
    "OCUPOLY_PEOPLE_COUNTER": "Ocupoly People Counter",
    "OCUPOLY_PARKING_SENSOR": "Ocupoly Parking Sensor",
    "ADEUNIS_TEMP_4": "Adeunis Temp 4",
    "TALKPOOL_OY1110": "Talkpool OY1110",
    "TEKTELIC_SMART_ROOM_SENSOR": "Tektelic Smart Room Sensor"
  };

  function calculateLastDeviceStatus(deviceStatuses) {
    const a = deviceStatuses ? deviceStatuses.sort((a, b) => b.time - a.time)[0] : null;
    return a ? a : null;
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <RichTable name="Urządzenia" columns={
            [
              {
                id: 'name',
                numeric: false,
                disablePadding: false,
                label: 'Nazwa',
                render: (row) => (<Link className={classes.link}
                  to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>),
                visible: true
              },
              { id: 'id', numeric: false, disablePadding: false, label: 'ID', visible: true },
              {
                id: 'type',
                numeric: false,
                disablePadding: false,
                label: 'Typ',
                render: (row) => deviceTypeToName[row.type],
                visible: true
              },
              {
                id: 'lastSeen',
                numeric: false,
                disablePadding: false,
                label: 'Ostatnio widziane',
                visible: true
              },
              {
                id: 'lastBatteryVoltage',
                numeric: true,
                disablePadding: false,
                label: 'Ostatnie napięcie baterii (V)',
                visible: true
              }
            ]
          } initialRowsById={new Map(Array.from(devices.values()).filter(value => value.groupId === groupId).map(device => {
            const lastDeviceStatus = calculateLastDeviceStatus(deviceStatuses.get(device.device.id));
            return [device.device.id, {
              name: device.device.name,
              id: device.device.id,
              type: device.device.type,
              lastSeen: lastDeviceStatus ? moment(lastDeviceStatus.time).format("YYYY-MM-DD HH:mm:ss") : null,
              lastBatteryVoltage: lastDeviceStatus ? lastDeviceStatus.batteryVoltage ? lastDeviceStatus.batteryVoltage.value : null : null
            }];
          }))} initialOrderBy="name"
            initialOrder="asc"
            selectionEnabled
            filterEnabled
            sortingEnabled
            addDeleteEnabled={admin}
            addUri={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/create?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
    </Container>
  );
}