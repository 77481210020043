export default function HeatTransferSurface(name, area, coefficient) {
    this.name = name;
    this.area = area;
    this.coefficient = coefficient;
}

HeatTransferSurface.fromJson = function (json) {
    if (json) {
        return new HeatTransferSurface(
            json.name,
            json.area,
            json.coefficient
        );
    } else return null;
};