import TimedValue from "./TimedValue"
import RxMetadata from "./RxMetadata";
import * as moment from "moment";

export default function DeviceStatus(deviceId,
    time,
    seqNo,
    timeSinceLastSeen,
    temperature,
    input1Temperature,
    input2Temperature,
    humidity,
    atmosphericPressure,
    totalEntryCount,
    totalExitCount,
    occupancy,
    totalOpenCount,
    totalCloseCount,
    totalWaterLeakCount,
    position,
    altitude,
    distance,
    batteryVoltage,
    batteryLow,
    rxMetadata) {
    this.deviceId = deviceId;
    this.time = time;
    this.seqNo = seqNo;
    this.timeSinceLastSeen = timeSinceLastSeen;
    this.temperature = temperature;
    this.input1Temperature = input1Temperature;
    this.input2Temperature = input2Temperature;
    this.humidity = humidity;
    this.atmosphericPressure = atmosphericPressure;
    this.totalEntryCount = totalEntryCount;
    this.totalExitCount = totalExitCount;
    this.occupancy = occupancy;
    this.totalOpenCount = totalOpenCount;
    this.totalCloseCount = totalCloseCount;
    this.totalWaterLeakCount = totalWaterLeakCount;
    this.position = position;
    this.altitude = altitude;
    this.distance = distance;
    this.batteryVoltage = batteryVoltage;
    this.batteryLow = batteryLow;
    this.rxMetadata = rxMetadata;

    this.isAlarm = (
        (this.batteryLow && this.batteryLow.value && this.batteryLow.time.getTime() === this.time.getTime())
    );

    this.isReading = !this.isAlarm;
}

DeviceStatus.fromJson = function (json) {
    return new DeviceStatus(
        json.deviceId,
        new Date(Date.parse(json.time)),
        json.seqNo,
        json.timeSinceLastSeen ? moment.duration(json.timeSinceLastSeen) : null,
        TimedValue.fromJson(json.temperature),
        TimedValue.fromJson(json.input1Temperature),
        TimedValue.fromJson(json.input2Temperature),
        TimedValue.fromJson(json.humidity),
        TimedValue.fromJson(json.atmosphericPressure),
        TimedValue.fromJson(json.totalEntryCount),
        TimedValue.fromJson(json.totalExitCount),
        TimedValue.fromJson(json.occupancy),
        TimedValue.fromJson(json.totalOpenCount),
        TimedValue.fromJson(json.totalCloseCount),
        TimedValue.fromJson(json.totalWaterLeakCount),
        TimedValue.fromJson(json.position),
        TimedValue.fromJson(json.altitude),
        TimedValue.fromJson(json.distance),
        TimedValue.fromJson(json.batteryVoltage),
        TimedValue.fromJson(json.batteryLow),
        RxMetadata.fromJson(json.rxMetadata));
};