import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TimedValue from "./TimedValue";
import TimedValuePanel from "./TimedValuePanel";
import TimedValueChart from "./TimedValueChart";
import AlarmPanel from "./AlarmPanel";
import { calculateState, calculateUsage, calculateAlarms3, loraDataRateToValue } from "./common";
import Divider from "@material-ui/core/Divider";
import { GoogleMap, Marker } from "./GoogleMap";
import Title from "./Title";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    panel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 144,
    },
    chartPanel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 233,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    mapPanel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    map: {
        height: 600,
        width: '100%'
    }
}));

export default function DeviceDashboard({ device, deviceStatuses }) {
    const classes = useStyles();

    const readingStatuses = deviceStatuses.filter(status => status.isReading);
    const alarmStatuses = deviceStatuses.filter(status => status.isAlarm);

    function areAllNull(a, field) {
        return a.map(a => a[field]).every((val, i, arr) => val === null);
    }

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        {!areAllNull(readingStatuses, "occupancy") && <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.panel}>
                                <TimedValuePanel title="Zajętość"
                                    unit=""
                                    color="black"
                                    timedValue={readingStatuses[0].occupancy ? readingStatuses[0].occupancy : null}
                                    onClick={{}}
                                    precision="0" />
                            </Paper>
                        </Grid>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {/*<Paper className={classes.mapPanel}>
                    <Map
                        id="myMap"
                        apiKey="AIzaSyDnhoSzehCm0Lb9iFF38n6_mG2-JX5tY7A"
                        options={{
                            center: { lat: 53.2612118, lng: 16.4339246 },
                            zoom: 14,
                            fullscreenControl: false,
                            streetViewControl: false,

                        }}
                        onMapLoad={map => {

                        }}
                        className={classes.map}
                    >
                        <Marker id = "" name = "1" lat = "53.2612118" long = "16.4339246"/>
                    </Map>
                </Paper>*/}
                {/* Column 1 */}
                {!areAllNull(readingStatuses, "position") && <Grid item xs={12} md={12} lg={12}>
                    <Paper className={classes.mapPanel}>
                        <Title>Pozycja</Title>
                        <GoogleMap
                            id="myMap"
                            apiKey="AIzaSyDnhoSzehCm0Lb9iFF38n6_mG2-JX5tY7A"
                            options={{
                                center: { lat: 53.2612118, lng: 16.4339246 },
                                zoom: 14,
                                fullscreenControl: false,
                                streetViewControl: false,

                            }}
                            onMapLoad={map => {

                            }}
                            className={classes.map}
                        >
                            {readingStatuses.filter(a => a.position != null && a.position.value.lat !== 0 && a.position.value.long !== 0).map(s => <Marker id={s.deviceId} name={s.deviceName} time={s.position.time} lat={s.position.value.lat} long={s.position.value.long} />)}
                        </GoogleMap>
                    </Paper>
                </Grid>}
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        {!areAllNull(readingStatuses, "temperature") && <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="Temperatura"
                                    label=" °C"
                                    color="black"
                                    entries={readingStatuses.filter(a => a.temperature != null).map(a => {
                                        return a.temperature;
                                    })}
                                    precision="3" />
                            </Paper>
                        </Grid>}
                        {!areAllNull(readingStatuses, "input1Temperature") && <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="Temperatura: wejście 1"
                                    label=" °C"
                                    color="black"
                                    entries={readingStatuses.filter(a => a.input1Temperature != null).map(a => {
                                        return a.input1Temperature;
                                    })}
                                    precision="3" />
                            </Paper>
                        </Grid>}
                        {!areAllNull(readingStatuses, "input2Temperature") && <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="Temperatura: wejście 2"
                                    label=" °C"
                                    color="black"
                                    entries={readingStatuses.filter(a => a.input2Temperature != null).map(a => {
                                        return a.input2Temperature;
                                    })}
                                    precision="3" />
                            </Paper>
                        </Grid>}
                    </Grid>
                </Grid>
                {/* Column 2 */}
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        {!areAllNull(readingStatuses, "humidity") && <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="Wilgotność"
                                    unit=" \%"
                                    color="black"
                                    entries={readingStatuses.filter(a => a.humidity != null).map(a => {
                                        return a.humidity;
                                    })}
                                    precision="3" />
                            </Paper>
                        </Grid>}

                    </Grid>
                </Grid>
                {/* Column 3 */}
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>

                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {/* Column 1 */}
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="LoRaWAN DR"
                                    label="wartość"
                                    color="black"
                                    entries={deviceStatuses.map(status => {
                                        return new TimedValue(status.time, loraDataRateToValue(status.rxMetadata.dataRate));
                                    }
                                    )}
                                    precision="3" />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Column 2 */}
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="RSSI"
                                    label="dBm"
                                    color="black"
                                    entries={deviceStatuses.map(status => {
                                        return new TimedValue(status.time, status.rxMetadata.gwInfo[0].rssi);
                                    }
                                    )}
                                    precision="3" />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Column 3 */}
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="SNR"
                                    label="dB"
                                    color="black"
                                    entries={deviceStatuses.map(status => {
                                        return new TimedValue(status.time, status.rxMetadata.gwInfo[0].snr);
                                    }
                                    )}
                                    precision="3" />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}