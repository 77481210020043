import React from 'react';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import Title from './Title';
import moment from "moment";
import { round } from "./common";

function createData(time, value) {
    return { time, value };
}

//#556CD6

export default function TimedValueChart({ title, label, color, entries, precision }) {
    var tmp = entries.map(entry => createData(entry !== null && entry.time ? moment(entry.time).format("L LTS") : null, round(entry.value, precision)));
    return (
        <React.Fragment>
            <Title>{title}</Title>
            <ResponsiveContainer>
                <LineChart
                    data={tmp.slice().reverse()}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis dataKey="time" />
                    <YAxis domain={["auto", "auto"]}>
                        <Label angle={270} position="left" style={{ textAnchor: 'middle' }}>
                            {label}
                        </Label>
                    </YAxis>
                    <Line type="monotone" dataKey="value" stroke={color} dot={true} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Tooltip separator=": " formatter={(a, b, c) => {
                        return [a.toString(), label]
                    }} />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
