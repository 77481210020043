import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import DeviceForm from "./DeviceForm";
import TimeRange from "./TimeRange";
import {
    useHistory,
    useParams
} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import * as moment from "moment";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}));

export default function DeviceCreate({ ds, since, until }) {
    const classes = useStyles();
    const history = useHistory();

    const params = useParams();

    const organizationId = params.organizationId;
    const groupId = params.groupId;

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    function onSubmit(device) {
        ds.addDevice(organizationId, device.id, device, () => {
            //setSnackbarOpen(true);
            history.push("/organizations/" + organizationId + "/devices?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : null));
        });
    }

    return (
        <React.Fragment>
            <Container maxWidth={false} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <DeviceForm initialDevice={{
                            notificationMsisdns: [],
                            notificationEmailAddresses: [],
                            timeZoneOffset: "+00:00",
                            type: "DRAGINO_LHT65"
                        }} onSubmit={onSubmit} admin={true} />
                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                onClose={event => setSnackbarOpen(false)}
                autoHideDuration={2000}
                message="Urządzenie dodane"
            />
        </React.Fragment>
    );
}