import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { alpha, lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import { convertArrayToCSV } from "convert-array-to-csv";
import { saveAs } from "file-saver"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from '@material-ui/icons/FilterList';
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";
import ReplayIcon from "@material-ui/icons/Replay";
import LensIcon from '@material-ui/icons/Lens';
import { CloudDownload } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

function descendingComparator(a, b, orderBy) {
    const as = a[orderBy] != null ? (isNaN(a[orderBy]) ? (a[orderBy].replaceAll("/", " ").trim().replaceAll(",", ".").split(/\s+/)).map(a => a.trim()) : [a[orderBy]]) : [];
    const bs = b[orderBy] != null ? (isNaN(b[orderBy]) ? (b[orderBy].replaceAll("/", " ").trim().replaceAll(",", ".").split(/\s+/)).map(a => a.trim()) : [b[orderBy]]) : [];

    const l = Math.min(as.length, bs.length);

    let i;
    for (i = 0; i < l; i++) {
        let bb = bs[i];
        let aa = as[i];

        if (!isNaN(bb) && !isNaN(aa)) {
            bb = parseFloat(bb);
            aa = parseFloat(aa);
        }

        if (bb < aa) {
            return -1;
        } else if (bb > aa) {
            return 1;
        }
    }

    if (bs.length < as.length) {
        return -1;
    } else if (bs.length > as.length) {
        return 1;
    } else {
        return 0;
    }
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({
    selectionEnabled,
    sortingEnabled,
    columns,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {selectionEnabled && <TableCell padding="checkbox" style={{ backgroundColor: "white" }}>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>}
                {columns.filter(column => column.visible).map((column, index) => (
                    <TableCell
                        style={{ backgroundColor: "white" }}
                        key={index}
                        align="left"
                        padding={column.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        {column.label && <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={sortingEnabled ? createSortHandler(column.id) : () => {
                            }}
                        >
                            {column.label}
                            {orderBy === column.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 0,
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
        paddingLeft: 0,
        marginLeft: 0
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        lineHeight: "1rem"
    },
    iconLink: {
        color: theme.palette.text.secondary,
        textDecoration: "none",
        lineHeight: "1rem"
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '10ch',
        [theme.breakpoints.up('sm')]: {
            //width: '12ch',
            '&:focus': {
                width: '24ch',
            },
        },
    },

}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {
        name,
        numSelected,
        search,
        addDeleteEnabled,
        add,
        deleteSelected,
        downloadEnabled,
        download,
        downloadTypes
    } = props;

    /*function onFileSelected(event) {
        let reader = new FileReader();

        // If we use onloadend, we need to check the readyState.
        reader.onloadend = function(evt) {
            if (evt.target.readyState === FileReader.DONE) { // DONE == 2
                Papa.parse(evt.target.result).data.forEach(fields => {
                    console.log(fields)
                });
            }
        };
        reader.readAsText(event.target.files[0]);
    }*/

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (exportType) => {
        setAnchorEl(null);
        download(exportType);
    };

    return (
        <React.Fragment>
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {numSelected > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        Wybrano: {numSelected}
                    </Typography>
                ) : (
                    <Typography className={classes.title} variant="button" id="tableTitle" component="div">
                        {name}
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <React.Fragment>
                        {addDeleteEnabled && <Tooltip title="Usuń">
                            <IconButton aria-label="delete">
                                <DeleteIcon onClick={event => setDialogOpen(true)} />
                            </IconButton>
                        </Tooltip>}
                        {downloadEnabled && <Tooltip title="Pobierz">
                            <IconButton aria-label="download" color="default" onClick={handleClick}>
                                <CloudDownload />
                            </IconButton>
                        </Tooltip>}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {/*<div className={classes.search}>
                            <IconButton color="default" className={classes.searchIcon}>
                                <SearchIcon/>
                            </IconButton>
                            <InputBase
                                placeholder="Szukaj"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={search}
                                type="search"
                                inputProps={{'aria-label': 'search'}}
                            />
                        </div>*/}
                        {addDeleteEnabled && <Tooltip title="Dodaj">
                            <IconButton aria-label="add">
                                <AddIcon onClick={event => add()} />
                            </IconButton>
                        </Tooltip>}
                        {downloadEnabled && <Tooltip title="Pobierz">
                            <IconButton aria-label="download" color="default" onClick={handleClick}>
                                <CloudDownload />
                            </IconButton>
                        </Tooltip>}
                    </React.Fragment>
                )}
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {downloadTypes.map(type => {
                        return <MenuItem key={type.type}
                            onClick={event => handleClose(type.type)}>{type.name}</MenuItem>;
                    })}
                </Menu>
            </Toolbar>
            <Dialog
                open={dialogOpen}
            //onClose={handleDialogClose}
            >
                <DialogTitle>{"Usuń " + numSelected + " wiersz?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={event => setDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={event => {
                        deleteSelected();
                        setDialogOpen(false);
                    }} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    tableContainer: {
    },
    table: {
        whiteSpace: 'nowrap',
    },
    tableCell: {
        whiteSpace: 'nowrap',
    },
    tableCellUsageCold: {
        whiteSpace: 'nowrap',
        color: 'blue',
    },
    tableCellUsageHot: {
        whiteSpace: 'nowrap',
        color: 'red',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(0, 0, 0, 0)
    },
    filter: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(.6, 0, 0, 1.6)
    },
    filterInput: {
        margin: theme.spacing(0, 0, 0, 0),
        padding: theme.spacing(0, 0, 0, 0)
    }
}));

export default function RichEmbeddedTable({
    name,
    columns,
    rows,
    setRows,
    emptyRow,
    initialOrderBy,
    initialOrder,
    filterEnabled = false,
    sortingEnabled = false,
    selectionEnabled = false,
    addDeleteEnabled = false,
    downloadEnabled = false,
    downloadTypes = [],
    onDelete = () => {
    },
    onDownload = () => {
    },
    className
}) {
    const classes = useStyles();
    const [orderBy, setOrderBy] = React.useState(initialOrderBy);
    const [order, setOrder] = React.useState(initialOrder);
    const [selected, setSelected] = React.useState([]);

    const [filters, setFilters] = React.useState(new Map());

    downloadTypes.unshift({ type: "CSV", name: "CSV" });

    // const [rows, setRows] = React.useState(Array.from(initialRowsById.values()).filter(obj =>
    //     Object.values(obj).every((a, index) => {
    //         const columnId = columns[index].id;
    //         const phrase = columnId ? filters.get(columnId) : null;
    //         return (phrase != null) ? (obj[columnId] ? obj[columnId].toString().includes(phrase) : false) : true;
    //     })));

    function search(columnId, phrase) {
        setSelected([]);
        if (phrase == null) {
            setFilters(value => {
                value.delete(columnId);
                return value;
            });
        } else {
            setFilters(value => {
                value.set(columnId, phrase);
                return value;
            });
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    /*const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };*/

    const isSelected = (name) => selected.indexOf(name) !== -1;

    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function resetSelected(event) {
        //onReset(selected);
        setSelected([]);
    }

    function deleteSelected(event) {
        onDelete(selected);
        setSelected([]);
    }

    function add() {
        rows.push(emptyRow);
        setRows(rows);
    }

    function download(downloadType) {
        if (downloadType === "CSV") {
            const columnLabels = columns.filter(column => column.visible).map(e => e.label);

            const csv = convertArrayToCSV(rows.map(row => {
                const fields = columns.filter(column => column.visible).map((column, index) => column.render ? (column.render(row) ? column.render(row) : "") : (row[column.id] ? row[column.id] : ""));
                return Object.values(fields).splice(0);
            }), {
                header: columnLabels,
                separator: ','
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "download.csv");
        } else {
            onDownload(selected, downloadType);
        }
    }

    return (
        <div className={classes.root}>
            <EnhancedTableToolbar name={name} numSelected={selected.length} search={search}
                addDeleteEnabled={addDeleteEnabled} add={add}
                deleteSelected={deleteSelected} downloadEnabled={downloadEnabled}
                download={download} downloadTypes={downloadTypes} />
            <TableContainer className={className}>
                <Table
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                    stickyHeader
                >
                    <EnhancedTableHead
                        selectionEnabled={selectionEnabled}
                        sortingEnabled={sortingEnabled}
                        columns={columns.filter(column => column.visible)}
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {filterEnabled && <TableRow
                            //hover
                            role="checkbox"
                            tabIndex={-1}
                            key="filter"
                        >
                            {selectionEnabled && <TableCell padding="checkbox" className={classes.filter}>
                                <FilterListIcon />
                            </TableCell>}
                            {columns.filter(column => column.visible).map(column => {
                                return (<TableCell id={column.id}
                                    key={column.id}
                                    padding={column.disablePadding ? "none" : "normal"}
                                    className={classes.tableCell}>
                                    <TextField
                                        margin="normal"
                                        value={filters.get(column.id) ? filters.get(column.id) : ""}
                                        className={classes.filterInput}
                                        InputProps={{
                                            classes: {
                                                input: classes.filterInput,
                                            },
                                        }}
                                        onChange={event => {
                                            const value = event.target.value !== "" ? event.target.value : null;
                                            search(column.id, value);
                                        }}
                                        fullWidth
                                    />
                                    {/*<InputBase
                                            placeholder=""
                                            onChange={(event) => search(column.id, event.target.value)}
                                            type="search"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{'aria-label': 'filter'}}
                                        />*/}
                                </TableCell>);
                            })}
                        </TableRow>}
                        {stableSort(rows, getComparator(order, orderBy))
                            //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => {
                                const isItemSelected = isSelected(row.id);

                                return (
                                    <TableRow
                                        //hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={rowIndex}
                                        selected={isItemSelected}
                                    >
                                        {selectionEnabled && <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                onClick={(event) => handleClick(event, rowIndex)}
                                            //inputProps={{'aria-labelledby': labelId}}
                                            />
                                        </TableCell>}
                                        {columns.map((column, index) => {
                                            return (<TableCell id={column.id} key={index}
                                                padding={column.disablePadding ? "none" : "normal"}
                                                className={classes.tableCell}>
                                                <TextField
                                                    name=""
                                                    type={column.numeric ? "number" : "text"}
                                                    margin="normal"
                                                    inputProps={column.numeric ? {
                                                        step: "0.001"
                                                    } : {}}
                                                    value={row[column.id]}
                                                    onChange={event => {
                                                        row[column.id] = event.target.value;
                                                        
                                                        rows[rowIndex] = row;
                                                        setRows(rows);
                                                    }}
                                                    helperText=""
                                                    fullWidth
                                                //disabled={!admin}
                                                /></TableCell>);
                                        })}
                                    </TableRow>
                                );
                            })}
                        {/*{emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}*/}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*<TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />*/}
        </div>
    );
}