import TimeRange from "./TimeRange";
import HeatTransferSurface from "./HeatTransferSurface";

export default function Group(
    id,
    name,
    type,
    notificationTimeRange,
    notificationDaysOfWeek,
    floorArea,
    deliveredHeat,
    heatingCost,
    heatingCostCurrency,
    externalHeatTransferSurfaces
) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.notificationTimeRange = notificationTimeRange;
    this.notificationDaysOfWeek = notificationDaysOfWeek;
    this.floorArea = floorArea;
    this.deliveredHeat = deliveredHeat;
    this.heatingCost = heatingCost;
    this.heatingCostCurrency = heatingCostCurrency;
    this.externalHeatTransferSurfaces = externalHeatTransferSurfaces;

    this.toJson = function () {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            notificationTimeRange: "{\"from\":\"07:00:00\",\"to\":\"17:00:00\"}",
            notificationDaysOfWeek: "[\"MONDAY\",\"TUESDAY\",\"WEDNESDAY\",\"THURSDAY\",\"FRIDAY\"]",
            floorArea: this.floorArea,
            deliveredHeat: this.deliveredHeat,
            heatingCost: this.heatingCost,
            heatingCostCurrency: this.heatingCostCurrency,
            externalHeatTransferSurfaces: this.externalHeatTransferSurfaces.map(value => value.toJson)
        };
    }
}

Group.fromJson = function (json) {
    return new Group(
        json.id,
        json.name,
        json.type,
        json.notificationTimeRange ? TimeRange.fromJson(json.notificationTimeRange) : null,
        json.notificationDaysOfWeek,
        json.floorArea,
        json.deliveredHeat,
        json.heatingCost,
        json.heatingCostCurrency,
        json.externalHeatTransferSurfaces ? json.externalHeatTransferSurfaces.map(value => HeatTransferSurface.fromJson(value)) : []);
};