import React from 'react';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import moment from "moment";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import { round } from "./common";

export default function TimedValuePanel({ title, unit, color, timedValue, precision }) {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Typography component="p" variant="h4" style={{
        color: (timedValue === null || timedValue.value || timedValue.value === 0) ? color : "black"
      }}>{timedValue === null || timedValue.value === 1 / 0 ? "∞" : (timedValue.value || timedValue.value === 0) ? round(timedValue.value, precision) + unit : <Tooltip title="Brak danych lub błędne ustawienia"><WarningIcon style={{ color: "red" }} /></Tooltip>}</Typography>
      <Typography color="textSecondary" style={{
        fontSize: "0.875rem",
        marginTop: 5
      }}>{timedValue !== null && timedValue.time ? moment(timedValue.time).format("L LTS") : ""}</Typography>
    </React.Fragment>
  );
}