import React from 'react';
import { useParams } from "react-router-dom";
import DeviceDashboard from "./DeviceDashboard";
import DeviceReadings from "./DeviceReadings";
import DeviceAlarms from "./DeviceAlarms";
import DeviceSettings from "./DeviceSettings";
import TabLayout from "./TabLayout";
import DeviceCalibrations from "./DeviceCalibrations";

export default function DeviceLayout({ ds, since, until, admin }) {
    const params = useParams();

    const organizationId = params.organizationId;
    const groupId = params.groupId;
    const deviceId = params.deviceId;

    const [device, setDevice] = React.useState(null);

    React.useEffect(() => {
        ds.retrieveDevice(organizationId, deviceId,
            value => setDevice(value))
    }, [organizationId, groupId, deviceId]);

    const [groupDeviceAttributes, setGroupDeviceAttributes] = React.useState(null);

    React.useEffect(() => {
        ds.retrieveGroupDeviceAttributes(groupId, deviceId,
            value => setGroupDeviceAttributes(value))
    }, [organizationId, groupId, deviceId]);

    function updateDevice(device, callback) {
        ds.updateGroupDevice(groupId, deviceId, device, callback);
    }

    function updateGroupDeviceAttributes(groupDeviceAttributes, callback) {
        ds.updateGroupDeviceAttributes(groupId, deviceId, groupDeviceAttributes, callback);
    }

    const [deviceStatuses, setDeviceStatuses] = React.useState([]);

    React.useEffect(() => {
        const source = ds.receiveDeviceStatuses(
            deviceId,
            since,
            until);

        let opening = true;
        source.onMessages(incomingDeviceStatuses => {
            setDeviceStatuses(prevState => {
                let updated = opening ? [] : prevState.slice();
                opening = false;
                incomingDeviceStatuses.forEach(value => updated.push(value));
                return updated.sort((a, b) => b.time - a.time);
            });
        });

        return function cleanup() {
            source.close();
        };
    }, [organizationId, groupId, deviceId, since, until]);

    return (device && <TabLayout tabs={
        [
            { label: "Tablica", name: "dashboard", children: <DeviceDashboard device={device} deviceStatuses={deviceStatuses} /> },
            { label: "Odczyty", name: "readings", children: <DeviceReadings device={device} deviceStatuses={deviceStatuses} /> },
            //{label: "Alarmy", name: "alarms", children: <DeviceAlarms device={device} deviceStatuses={deviceStatuses}/>},
            //{label: "Kalibracje", name: "calibrations", children: <DeviceCalibrations device={device} deviceStatuses={deviceStatuses}/>},
            { label: "Ustawienia", name: "settings", children: <DeviceSettings device={device} groupDeviceAttributes={groupDeviceAttributes} onDeviceUpdate={updateDevice} onGroupDeviceAttributesUpdate={updateGroupDeviceAttributes} admin={admin} /> }
        ]
    } />);
}